import React, { useEffect, useState, useCallback } from 'react';
import {
    auth
} from '../../utils/firebaseConfig';
import {
    Box,
    Button,
    Flex,
    FormControl,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    useToast,
    Text,
    FormLabel,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Link,
    Image
} from '@chakra-ui/react';
import { COLOR1, COLOR2 } from '../../utils/ColorConstants';
import { isSignInWithEmailLink, signInWithEmailLink, updatePassword } from 'firebase/auth';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { sendPasswordResetEmail as firebaseSendPasswordResetEmail } from 'firebase/auth';


/**
 * Accept Component
 *
 * The `Accept` component is responsible for handling the activation of user accounts using email links.
 *
 * @component
 * @example
 * <Accept />
 */
const Accept = () => {
    const navigate = useNavigate();
    const toast = useToast();

    const [showTop, setShowTop] = useState(false);
    const [showBottom, setShowBottom] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [isEmailVerified, setIsEmailVerified] = useState(true);
    const [verificationMessage, setVerificationMessage] = useState('');

    const displayToast = useCallback((title: string, description: string, status: 'success' | 'error' | 'warning' | 'info' | undefined) => {
        toast({
            title: title,
            description: description,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top'
        })
    }, [toast]);


    /**
     * Toggles the visibility of the password input.
     */
    const handleClickTop = () => setShowTop(!showTop);

    /**
     * Toggles the visibility of the confirm password input.
     */
    const handleClickBottom = () => setShowBottom(!showBottom);

    const params = useParams();

    useEffect(() => {
        /**
         * Verifies the email confirmation link and updates user account activation status.
         */
        const verifyEmailCode = async () => {
            const res = await axios.get(process.env.REACT_APP_BACKEND_URL + 'users/get/activated/' + params.id, (
                {
                    headers: {
                        'Accept': 'application/json'
                    }
                }));

            if (res.data.activated) {
                navigate('/');
                toast({
                    title: 'Account has already been activated.',
                    description: 'If you did not activate your account, please contact us now.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                });
            } else if (auth.currentUser?.getIdToken == null) {
                let email: string | null = null;
                let emailAttempts = 0;
                const maxAttempts = 3;

                if (isSignInWithEmailLink(auth, window.location.href.toString())) {
                    while (email !== res.data.emailAddress && emailAttempts < maxAttempts) {
                        emailAttempts++;
                        const attemptMessage = "Please provide your email for confirmation:";

                        email = window.prompt(attemptMessage);

                        // Normalize emails for comparison (trim whitespace and convert to lowercase)
                        if (email && email.trim().toLowerCase() === res.data.emailAddress.trim().toLowerCase()) {
                            // Match found with normalization, use the email from the database for consistency
                            email = res.data.emailAddress;
                            break;
                        }
                    }

                    if (emailAttempts >= maxAttempts && email !== res.data.emailAddress) {
                        // User exceeded max attempts, show password reset option
                        setIsEmailVerified(false);
                        setVerificationMessage('Email verification failed. You can reset your password below.');
                        return;
                    }

                    setEmailAddress(email!);
                    await signInWithEmailLink(auth, email!, window.location.href.toString())
                        .then(() => {
                        })
                        .catch((error) => {
                        });
                } else {
                    navigate('/');
                    displayToast(
                        'Unconfirmed Email Link',
                        'Try the email link again.',
                        'error'
                    );
                }
            } else {
                navigate('/');
                toast({
                    title: 'Unconfirmed Email Link',
                    description: 'Try the email link again.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                });
            }
        };

        verifyEmailCode();
    }, [displayToast, navigate, params.id, toast]);

    /**
     * Handles the password update and account activation process.
     *
     * @param {Event} e - The form submission event.
     */
    async function handlePasswordUpdate(e: any) {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        try {
            const user = auth.currentUser;
            if (user) {
                await updatePassword(user, newPassword);
                await axios.post(process.env.REACT_APP_BACKEND_URL + 'users/set/activated/' + params.id, (
                    {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }));
                navigate('/');
                toast({
                    title: 'Account was activated.',
                    description: 'Enjoy!',
                    status: 'info',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                });
            } else {
                alert('No authenticated user found, try inputting your email again (or) Your email link may have expired, try to resend the email link below.');
            }
        } catch (error) {
            console.error('Error updating password:', error);
        }
    }

    const handlePasswordReset = async () => {
        try {
            if (!emailAddress) {
                displayToast('Error', 'Please enter your email address', 'error');
                return;
            }

            await firebaseSendPasswordResetEmail(auth, emailAddress);
            displayToast(
                'Password Reset Email Sent',
                'Check your inbox for instructions to reset your password',
                'success'
            );
        } catch (error) {
            displayToast(
                'Error',
                'Failed to send password reset email. Please try again.',
                'error'
            );
        }
    };

    return (
        <Flex w="100%" h="100vh" align="center" justify="center">
            <Box
                p={8}
                maxWidth="500px"
                borderWidth={1}
                borderRadius={8}
                boxShadow="lg"
            >
                <Box textAlign="center">
                    <Image
                        src="/logo-dark.png"
                        width={150}
                        height={150}
                        alt="logo"
                    />
                    <Heading>Welcome to the Community!</Heading>
                </Box>
                <Box my={4} textAlign="left">
                    {isEmailVerified ? (
                        <>
                            <FormControl mt={6} isRequired>
                                <FormLabel>Password</FormLabel>
                                <InputGroup size="md">
                                    <Input
                                        pr="4.5rem"
                                        type={showTop ? "text" : "password"}
                                        placeholder="Enter password"
                                        onChange={(event) => {
                                            setNewPassword(event.currentTarget.value);
                                        }}
                                    />
                                    <InputRightElement width="4.5rem">
                                        <Button h="1.75rem" size="sm" onClick={handleClickTop}>
                                            {showTop ? "Hide" : "Show"}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <FormControl mt={6} isRequired>
                                <FormLabel>Confirm Password</FormLabel>
                                <InputGroup size="md">
                                    <Input
                                        pr="4.5rem"
                                        type={showBottom ? "text" : "password"}
                                        placeholder="Confirm password"
                                        onChange={(event) => {
                                            setConfirmPassword(event.currentTarget.value);
                                        }}
                                    />
                                    <InputRightElement width="4.5rem">
                                        <Button h="1.75rem" size="sm" onClick={handleClickBottom}>
                                            {showBottom ? "Hide" : "Show"}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <Button
                                color={COLOR1}
                                bgColor={COLOR2}
                                borderRadius='45px'
                                p={7}
                                fontSize={{ base: '17px', md: '20px' }}
                                mt={'4'}
                                onClick={handlePasswordUpdate}
                            >
                                Accept
                            </Button>
                        </>
                    ) : (
                        <>
                            <Alert status="warning" mb={6}>
                                <AlertIcon />
                                <Box>
                                    <AlertTitle>Email Verification Failed</AlertTitle>
                                    <AlertDescription>
                                        {verificationMessage}
                                    </AlertDescription>
                                </Box>
                            </Alert>

                            <FormControl mt={6}>
                                <FormLabel>Email Address</FormLabel>
                                <Input
                                    type="email"
                                    placeholder="Enter your email address"
                                    value={emailAddress}
                                    onChange={(e) => setEmailAddress(e.target.value)}
                                />
                            </FormControl>

                            <Button
                                colorScheme="blue"
                                width="full"
                                mt={6}
                                onClick={handlePasswordReset}
                            >
                                Send Password Reset Email
                            </Button>

                            <Text mt={4} textAlign="center">
                                <Link color="blue.500" href="/">
                                    Return to Home
                                </Link>
                            </Text>
                        </>
                    )}
                </Box>
            </Box>
        </Flex>
    );
};

export default Accept;
