import React, { useEffect, useState } from 'react';
import { Box, Container, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import axios from 'axios';
import AdminAccordian from '../components/AdminAccordian/AdminAccordian';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import EventsAdmin from './community-pages/events-admin';
import ChallengesAdmin from './community-pages/challenges-admin';
import PushNotificationAdmin from './community-pages/push-notif-admin';

interface Profile {
    id: string,
    bio: string,
    firstName: string,
    lastName: string,
    location: string,
    isAdmin: boolean,
    isAccepted: boolean
    previousWork: { link: string, value: string }[],
    selectedInterests: string[],
    selectedRoles: string[]
};

const Admin = () => {
    const token = useSelector((state: RootState) => state.authToken.value);
    const [shouldFetch, setShouldFetch] = useState(true);
    const loggedInProfile: any = useSelector((state: RootState) => state.profile.value);
    const navigate = useNavigate();
    async function getUsers(endpoint: string) {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + endpoint, {
            headers: {
                'Authorization': token,
                'Accept': 'application/json'
            }
        });
        return response;
    };

    const endPoint1 = 'accepted-users';
    const endPoint2 = 'applied-users';
    const { isLoading: appliedUsersLoading, error: appliedUsersError, data: appliedUsers } = useQuery<any>({
        queryKey: ['applied-users-data', endPoint2], queryFn: () => {
            return getUsers(endPoint2);
        },
        enabled: shouldFetch,
        onSettled: () => setShouldFetch(false),
    });
    const { isLoading: acceptedUsersLoading, error: acceptedUsersError, data: acceptedUsers } = useQuery<any>({
        queryKey: ['accepted-users-data', endPoint1], queryFn: () => {
            return getUsers(endPoint1);
        },
        enabled: shouldFetch,
        onSettled: () => setShouldFetch(false),
    });

    useEffect(() => {
        if (loggedInProfile?.email !== process.env.REACT_APP_SU_USER && !loggedInProfile?.isAdmin) {
            navigate('/');
        }
    }, []);

    return (
        <Box minW='100%'>
            <Container maxW={1440} height='100%'>
                <Tabs align='center'>
                    <TabList>
                        <Tab> Users </Tab>
                        <Tab> Community </Tab>
                        <Tab> Push Notifications </Tab>
                    </TabList>
                    <TabPanels>
                        {/* User Tab */}
                        <TabPanel>
                            <Container maxW={1440} height='100%'>
                                <Tabs align='center'>
                                    <TabList>
                                        <Tab> Applied Users </Tab>
                                        <Tab> Accepted Users </Tab>
                                    </TabList>
                                    <TabPanels>
                                        {appliedUsersLoading ?
                                            <Spinner speed='1.25s' />
                                            :
                                            <>
                                                {
                                                    appliedUsers ? <TabPanel>
                                                        {
                                                            appliedUsers.data && appliedUsers.data.map((profile: any, idx: number) => {
                                                                return profile.firstName ? (
                                                                    <AdminAccordian setShouldFetch={setShouldFetch} key={idx} {...profile} />
                                                                ) : null
                                                            })
                                                        }
                                                    </TabPanel> : <></>
                                                }
                                            </>
                                        }

                                        {acceptedUsersLoading ?
                                            <Spinner speed='1.25s' />
                                            :
                                            <>
                                                {
                                                    acceptedUsers ? <TabPanel>
                                                        {
                                                            acceptedUsers.data && acceptedUsers.data.map((profile: any, idx: number) => {
                                                                return profile.firstName ? (
                                                                    <AdminAccordian setShouldFetch={setShouldFetch} key={idx} {...profile} />
                                                                ) : null
                                                            })
                                                        }
                                                    </TabPanel> : <></>
                                                }
                                            </>
                                        }
                                    </TabPanels>
                                </Tabs>
                            </Container>
                        </TabPanel>
                        {/* Community Tab */}
                        <TabPanel>
                            <Container maxW={1440} height='100%'>
                                <Tabs align='center'>
                                    <TabList>
                                        <Tab> Events </Tab>
                                        <Tab> Grants </Tab>
                                        <Tab> Chats </Tab>
                                        <Tab> Challenges </Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            <Container size={'m'}>
                                                <EventsAdmin />
                                            </Container>
                                        </TabPanel>
                                        <TabPanel>
                                            <p>Coming Soon!</p>
                                        </TabPanel>
                                        <TabPanel>
                                            <p>Coming Soon!</p>
                                        </TabPanel>
                                        <TabPanel>
                                            <ChallengesAdmin />
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Container>
                        </TabPanel>
                        {/* Push Notifications Tab */}
                        <TabPanel>
                            <PushNotificationAdmin />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>
        </Box>
    )
}

export default Admin

